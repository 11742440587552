.course__title {
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 20px;
}

.course__title_selector_label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-left: 20px;
  margin-bottom: 4px;
  width: auto;
  text-align: left;
}

.resources {
  width: auto;
  background-color: #fff;
  box-shadow: 0px 12px 50px 10px rgba(51,102,255,0.18);
  border-radius: 12px;
  padding: 20px;
  margin: 15px 20px 0px 20px;
}

.resources p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.resources a[download] {
  color: #36f;
  color: var(--cy__blue);
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.resources a img {
  margin-right: 10px;
}

:global .courseSelector {
  width: auto;
  height: 50px;
  margin: 5px 20px 0px 20px;
  align-content: center;
  padding: 0px 5px 0px 5px;

  box-shadow: 0px 12px 50px 10px rgba(51,102,255,0.18) !important;
  border-radius: 12px;
  border-color: grey !important;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  color: #6f6f6f;
  color: var(--cy__darkgrey);
  cursor: pointer;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  position:relative;
}

:global .courseSelector:has(> .courseSelector__control--menu-is-open) {
  border-bottom: none !important;
  border-radius: 12px 12px 0px 0px !important;
}

:global .courseSelector__control {
  border-width: 0px !important;
  box-shadow: none !important;
}


:global .courseSelector__control:hover {
  border-color: grey !important;
  box-shadow: none !important;
}

:global .courseSelector__single-value {
  color: var(--cy__darkgrey) !important;
  color: #6f6f6f;
}

:global .courseSelector__indicator {
  color: var(--cy__darkgrey) !important;
  color: #6f6f6f;
}

:global .courseSelector__indicator:hover {
  color: var(--cy__blue) !important;
  color: #36f !important;
}

:global .courseSelector__menu {
  left: 0px !important;
  margin-top: 0px !important;
  border-radius: 0px 0px 12px 12px !important;
  overflow: hidden;
  box-shadow: 0px 12px 20px 0px #336624 !important;
  border: none;
}

:global .courseSelector__menu-list {
  padding-top: 0px !important;
}

:global .courseSelector__option {
  color: var(--cy--darkgray) !important;
  color: #6f6f6f !important;
  background-color: transparent !important;
}

:global .courseSelector__option--is-focused {
  background-color: transparent !important;
}

:global .courseSelector__option--is-focused {
  color: var(--cy--darkgray) !important;
  color: #6f6f6f !important;
  background-color: var(--cy__grey) !important;
  background-color: #f3f6ff !important;
}



.display {
  width: auto;
  position: relative;
  display: flex;
}

.display .content__container {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
  height: -webkit-calc(100% - 120px);
  height: calc(100% - 120px);
  background-color: #eaeaea;
  border-radius: 12px;
  margin: 60px;
}

.content__placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 800px;
}

.content__placeholder .svg__child {
  position: absolute;
  bottom: -8%;
  right: 22.5%;
}

.content__placeholder .svg__cloud1 :global {
  position: absolute;
  bottom: 33%;
  right: 15%;
  z-index: -1;
  -webkit-animation: moveClouds 30s linear infinite;
          animation: moveClouds 30s linear infinite;
}

.content__placeholder .svg__cloud2 :global {
  position: absolute;
  bottom: 26%;
  right: 8%;
  z-index: -1;
  -webkit-animation: moveCloudsDir2 20s linear infinite;
          animation: moveCloudsDir2 20s linear infinite;
}

.content__placeholder .svg__circle {
  position: absolute;
  bottom: -15%;
  right: -15%;
  z-index: -2;
}

.content__placeholder .text {
  position: absolute;
  top: 17%;
  right: 50%;
  font-size: 96px;
  font-weight: 400;
  line-height: 104px;
  color: #36f;
  color: var(--cy__blue);
}

:global .content__wrapper {
  width: auto;
  margin-top: 0px;
}


@media screen and (max-width: 1400px) {
  .content__placeholder .svg__child {
      max-height: 700px;
      right: 40px;
  }
  .content__placeholder .text {
      top: 22%;
      right: 42%;
      font-size: 64px;
      line-height: 75px;
  }
  .content__placeholder .svg__cloud1 {
      max-width: 600px;
      right: 23%;
      bottom: 40%;
  }
  .cy__content .display .content__container {
      margin: 60px 20px;
  }
}

@media screen and (max-width: 992px) {
  .cy__content .display {
      width: 100%;
      height: 100vh;
  }
  .cy__content .display .content__container {
      margin: 60px 15px;
  }
  .content__placeholder .text {
      top: 25%;
      left: 50%;
      font-size: 48px;
      line-height: 64px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      width: 100%;
      text-align: center;
      z-index: 5;
  }
  .content__placeholder .svg__child {
      bottom: -8%;
      right: -20px;
      max-height: 500px;
  }
  .content__placeholder .svg__cloud1 {
      bottom: unset;
      right: -80px;
      max-width: 330px;
      top: 10%;
  }
  .content__placeholder .svg__cloud2 {
      max-width: 300px;
      bottom: unset;
      top: 27%;
      left: -120px;
  }
  .course__title_selector_label {
      display: none;
  }
  .course__title {
      display: none;
  }
  .courseSelector {
      display: none;
  }
  .slides_wrapper {
      padding-right: 0px !important;
  }
}

.slides_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 6px;
  padding-right: 20px;
}

#change_lesson_controls {
  padding: 10px;
  display: none;
  flex-flow: row wrap;
  justify-content: space-between;
}

/*
.change_lesson_button {
  transition: 0s;
  height: 40px;
  top: -32px;
}

.change_lesson_button:disabled {
  color: white;
  background: lightgray;
}
*/

#notes {
  /*display: flex;*/
  flex-flow: row wrap;
  background: white;
  opacity: 0.8;
  padding: 1px 38px 1px 38px;
  /*visibility: hidden;*/
}

#notes a {
  word-break: break-all;
}

.container::-webkit-scrollbar {
  width: 12px;
}

.container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

@container (width < 400px) {
  .container {
    padding: 20px 10px 0 10px;
  }
  .course__title_selector_label {
    margin: 4px 4px 4px 4px !important;
  }
  .course__title {
    margin-left: 10px !important;
  }
  :global .courseSelector {
    font-size: 12px !important;
    margin: 4px 4px 4px 4px !important;
    height: 38px !important;
  }
  .resources {
    padding: 8px 12px !important;
    margin: 4px 4px 4px 4px !important;
    height: 40px !important;
  }
  .resources a {
    font-size: 10px !important;
  }
}



@container (width < 250px) {
  .course__title_selector_label {
    display: none !important;
  }
  .course__title {
    display: none !important;
  }
  :global .courseSelector {
    display: none !important;
  }
  .resources {
    display: none !important;
  }
}
