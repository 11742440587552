.top__panel {
  overflow: auto;
}

.bottom__panel {
  padding-top: 20px;
}

.copyicon {
  width: 20px;
  height: 20px;
  margin-bottom: 3px;
  margin-left: 10px;
  vertical-align: middle;
}


.membericon {
  width: 0.9em;
  height: 0.9em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.membericon_registered {
  margin-right: 0em !important;
}

.membericon_after {
  width: 0.9em;
  height: 0.9em;
  margin-left: 0.5em;
  /*margin-right: 0.5em;*/
  vertical-align: middle;
}

.attendant_version {
  font-size: 8px;
  margin-right: 0.5em;
}

.membercheckbox {
  margin-right: 0.5em;
}

.memberactivation {
  font-size: 10px;
  margin-left: 0.5em;
}

.user_lmodules {
  font-size: 10px;
  margin-left: 0.2em;
}
