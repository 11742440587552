body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cy__flex_container {
  display: flex;
  flex-direction: column;
}

.cy__flex_container .cy__flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cy__flex_gap_15px {
  gap: 15px;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  /* min-width: 2400px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #36f;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table thead {
  position: sticky;
  top: 0;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #36f;
}

.styled-table tbody tr:hover {
  color: #36f;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #36f;
  background-color: var(--cy__blue);
  border-radius: 12px;
  margin-bottom: 20px;
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
}

.flex-gap-1em {
  gap: 1em;
}

.flex-gap-05em {
  gap: 0.5em;
}
