.lab_panel {
  display: flow;
  margin: 20px;
}

.lab_image {
  display: inline-block;
  vertical-align: middle;
  img {
    width: 100%;
  }
}

.start_lab_panel {
  width: 450px;
  margin-left: 15px;
  display: inline-block;
  vertical-align: middle;
}

.start_lab_progressbar {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.start_lab_text {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
  button {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
    padding: 0px;
    font-size: 18px;
  }
  button:hover {
    color: #0056b3;
    text-decoration: underline;
    }
}

.visibility__hidden {
  visibility: hidden;
}

.content__container {
  margin-top: 10px;
}

:global .slide__container {
  width: 100%;
  overflow-y: auto;
  margin-right: 10px;
}

.content__iframe {
  border: 0;
  display: block;
}

.notes__container {
  &:global(.hidden) {
    display: none;
  }
  &:global(.shown) {
    display: block;
  }
  & a {
    word-break: break-all;
  }
}

.iframe__container {
  width: 100%;
  height: 100%;
  position: relative;
  /* z-index: -1 !important; */
}

.change_lesson_controls {
  position: absolute;
  display: flex;
  /*flex-flow: row wrap;*/
  justify-content: space-between;
  width: 100%;
  height: 0;
  bottom: 4px;
}

.notes_toggle_button {
  position: relative;
  transition: 0s;
  font-size: 14px;
  color: darkgrey;
  padding: 0px 14px !important;

  padding-top: 1px;
  margin-right: 0px;
  min-width: 80px;
  height: 24px;
  top: 9px;

  border-style: solid;
  border-width: 2px;
  border-color: var(--cy__grey);
  background: white;
  &:hover {
    color: #36f !important;
    color: var(--cy__blue);
    border-color: #36f !important;
    border-color: var(--cy__blue);
  } 
  &:global(.shown)::before {
    content: "Hide";
  }
  &:global(.hidden)::before {
    content: "Notes";
  }
  &:global(.hidden)::after {
    content: ">";
    margin-left: 6px;
    rotate: 90deg;
    font-size: 22px;
  }
  &:global(.shown)::after {
    content: "<";
    margin-left: 6px;
    rotate: 90deg;
    font-size: 22px;
  }
}

.narration {
  font-size: 14px;
  & tt {
    word-break: break-all;
  }
}

.narration_toggle_button {
  position: relative;
  font-size: 14px;
  color: darkgrey;

  left: -14px;
  top: 14px;
  margin-right: -12px;
  width: 22px;

  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: var(--cy__grey);
  background: white;

  &:hover {
    color: #36f !important;
    color: var(--cy__blue);
    border-color: #36f !important;
    border-color: var(--cy__blue);
  }

  &:global(.collapsed) {
    left: -5px;
    height: 100px;
    right: 12px;
  }
  &:global(.shown) {
    left: -20px;
    height: 75px;
  }

  &:global(.collapsed)::before {
    content: "Narration";
    transform: rotate(90deg);
    display: block;
    top:-24px;
    position:relative;
  }
  &:global(.collapsed)::after {
    content: "<";
    top:26px;
    position:relative;
  }
  &:global(.shown)::before {
    content: "Close";
    transform: rotate(90deg);
    display: block;
    top:-12px;
    position:relative;
  }
  &:global(.shown)::after {
    content: ">";
    top:14px;
    position:relative;
  }
}

:global .narration_highlight {
  color: black;
  background-color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

:global .narration_dehighlight {
  color: #C0C0C0;
  background-color: #f3f6ff;
  background-color: var(--cy__lightgrey);
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.change_lesson_button {
  position: relative;
  transition: 0.1s;
  height: 40px;
  font-size: 16px !important;
  padding: 0px 14px !important;
}

.change_lesson_button> a{
  color: white !important;
  font-size: 16px;
  padding: 0px 0px;
}

.change_lesson_button:disabled {
  color: white;
  background: lightgray;
}

:global(.btn)>a {
  color: white !important;
}