.sidebar_container {
  margin: 2em 0;
}

.row {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  gap: 1em;
}