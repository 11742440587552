.row {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  align-items: center;
}

.year_selector {
  width: 5.5em;
}

.month_selector {
  width: auto;
  padding: 8px 0px;
}

.month_changer {
  cursor: pointer;
  width: 16px;
  margin-left: -12px;
}