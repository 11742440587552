.navlink_item {
  text-decoration: none;
}

#create_new_cohort_div {
  margin-bottom: 2em;
}

.filter_form {
  margin-top: 2em;
}