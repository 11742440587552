.card {
    box-shadow: 0px 12px 50px 10px rgba(51, 102, 255, 0.18);
    border-radius: 12px;
    background-color: #fff;
    margin: 0px 15px 15px 15px;
    padding: 30px 20px;
    width: 100%;
    position: relative;
}

.card.marked-as-read {
    opacity: 0.5;
}

.mark-as-read-button {
    background-color: #3366ffaa;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;

    position: absolute;
    bottom: -10px;
    right: 10px;
}

.mark-as-read-button:hover {
    background-color: #3366ff;
    color: #fff;
}
