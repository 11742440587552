.feedbacks_container {
  height: 70vh;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
}

.feedback_table {
  min-width: 3500px;
}

.feedback_table_header {
  display: flex;
}

.feedback_table_header div {
  margin: 10px 20px;
}

.table_text_header {
  display: inline-block;
  text-align: left;
  /*margin-bottom: 5px;*/
}

.table_text {
  display: inline-block;
  width: 200px;
}

.table_text2 {
  display: inline-block;
  width: 200px;
}

.table_text3 {
  display: inline-block;
  width: 300px;
}

.table_text4 {
  display: inline-block;
  width: 400px;
}

.table_text5 {
  display: inline-block;
  width: 500px;
}

.table_text6 {
  display: inline-block;
  width: 600px;
}

.table_num_header {
  display: inline-block;
  width: 30px;
  text-orientation: revert;
  writing-mode: tb;
  transform: rotateZ(180deg);
  vertical-align: bottom;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 0px !important;
}

.table_num {
  display: inline-block;
  width: 30px;
  text-align: center;
  padding: 12px 12px !important;
}