.top__panel {
    display: flex;
}

.bottom__panel {
    display: flex;
}

.moving_splitter_parent {
    user-select: none;
    cursor: row-resize;
}

.moving_splitter_pointer_events {
    pointer-events: none;
}

.splitter {
    background-color: #f3f6ff;
    cursor: row-resize;
    width: 100%;
    height: 4px;
    min-height: 4px;
}

.content__placeholder {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 800px;
}

.content__placeholder .text {
    position: absolute;
    top: 17%;
    right: 50%;
    font-size: 96px;
    font-weight: 400;
    line-height: 104px;
    color: #36f;
    color: var(--cy__blue);
}