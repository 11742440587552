.container {
  display: flex;
  flex-direction: column;
}

.container .row {
  display: flex;
  font-size: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  padding-top: 10px;
  gap: 10px;
}

div.row>.col_stretch {
  flex-grow: 100;
}

div.row>.col_stretch>input {
  width: 100%;
}

div .form_label {
  text-align: right;
  clear: both;
  float: left;
  margin-right: 15px;

}

input.form_input {
  border: 1px solid lightgray;
}

textarea.form_input {
  border: 1px solid lightgray;
  width: 100%;
  resize: none;
}

input.date_input {
  width: auto;
}

input:disabled {
  color: grey;
}

textarea:disabled {
  color: grey;
}

p.label {
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #36f;
  color: var(--cy__blue);
}

.timeZone {
  margin-left: 10px;
  width: 70px;
}

.timeInput {
  margin-left: 10px;
  width: 130px;
}