.PasswordIcon {
    position: absolute;
    margin-top: 6px;
    margin-left: -26px;
    width: 16px;
}

.PasswordIcon svg {
    width: 100%;
}

.password_field {
    padding-right: 30px;
}

label.disabled {
    color: darkgray;
}

input:disabled {
    border-color:lightgray;
}

.password_field:disabled {
    border-color:lightgray;
}