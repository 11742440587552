.courses {
    width: auto;
    background-color: #fff;
    box-shadow: 0px 12px 50px 10px rgba(51,102,255,0.18);
    border-radius: 12px;
    padding: 20px 0 0 0;
    margin: 15px 20px 30px 20px;
}

.courses h2 {
    padding: 0 30px;
    /*max-width: 65%;*/
}
  
.courses .unit {
    font-size: 14px;
    color: darkred;
}

.courses .lessons_chevron {
    color: #6f6f6f;
    color: var(--cy__darkgrey);
    flex: inherit;
    align-self: center;
    padding-bottom: 7px;
    padding-right: 30px;
    font-size: 18px;
    float: right;
    cursor: pointer;
}

.courses .lessons_chevron:hover {
    color: #36f;
    color: var(--cy__blue);
}

.courses .list {
    width: 100%;
    overflow: hidden hidden;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
}

/*.courses .list */.course {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
}

.courses .list .course:nth-of-type(odd) {
    background-color: #f3f6ff;
    background-color: var(--cy__lightgrey);
}
  
.course__head {
    padding: 0 30px;
    margin-top: 4px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
}
  
.course__head .name {
    margin: 0;
    width: auto;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    padding-left: 18px;
    text-indent: -18px;
    margin-right: 10px;
}
  
.course__head .course__count {
    width: 60px;
    min-width: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: end;
        -ms-flex-pack: justify;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 500;
}
  
.course__head .course__count p {
    margin: 15px 0 10px;
}
  
.course__head .all {
    color: darkgray;
}
  
.course__head .course__count .done.progress {
    color: #0c9;
    color: var(--cy__green);
}
  
.course.highlight p {
    color: darkred;
    color: var(--cy__darkred);
}
  
.course.disable p {
    pointer-events: none;
    opacity: 0.4 !important;
}
  
.course__head .course__count  .chevron {
    color: var(--cy__darkgrey) !important;
    color: #6f6f6f;
    font-size: 0.75rem;
    margin-left: 15px;
    margin-top: 4px;
}

.course__head .course__count .chevron:hover {
    color: #36f !important;
    color: var(--cy__blue) !important;
}


.course__lessons {
    display: none;
}
  
.course.shown .course__lessons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin: 0;
    line-height: 21px !important;
}
  
.course.shown .course__lessons li {
    margin-top: 0px;
    margin-bottom: 7px;
    margin-left: 10px;
}
  
.course.shown .course__lessons li a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
}
  
.course.shown .course__lessons li.active {
    margin-left: 20px;
}
  
.course.shown .course__lessons li.active a {
    color: #36f !important;
    color: var(--cy__blue);
}
  
.course.shown .course__lessons li.done a {
    position: relative;
    color: #0c9;
    color: var(--cy__green);
}
  
.course.shown .course__lessons li.highlight a {
    position: relative;
    color: darkred;
    color: var(--cy__darkred);
}
  
.course.shown .course__lessons li.disable a {
    position: relative;
    pointer-events: none;
    opacity: 0.4 !important;
}
  
.course.shown .course__lessons li.done a::after {
    content: "✔";
    bottom: 0;
    left: 100%;
    position: absolute;
    margin-left: 4px;
}

:global .container {
    padding-top: 20px;
    container-type: inline-size;
    max-height: calc(100vh - 100px);
    padding: 30px 10px 0px 10px !important;
}

@container (width < 400px) {
    .course__count {
        display: none !important;
    }
    .course__head {
        padding: 0 20px !important;
    }
    .course__head .name {
        font-size: 14px !important;
        padding: 6px 12px !important;
        line-height: 20px !important;
        margin-bottom: -4px !important;
        margin-right: 0px !important;
    }
    .course__lessons {
        padding: 0 20px !important;
        line-height: 15px !important;
    }
    .course__lessons li {
        margin-bottom: 2px !important;
    }
    .course__lessons li a {
        font-size: 12px !important;
    }
    .course__title {
        font-size: 16px !important;
    }
    .courses {
        padding: 4px 0px 0px 0px !important;
        margin: 4px 4px 4px 4px !important;
    }
    .courses h2 {
        font-size: 16px !important;
        padding: 0px 12px !important;
        /*margin: 0px !important;*/
        line-height: 20px !important;
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }
    .courses .unit {
        font-size: 12px;
    }
}
  

@container (width < 150px) {
    .CaptionCont {
        display: none !important;
    }
    .content__wrapper {
        display: none !important;
    }
}
