.progressbar {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  
    & .label {
        display: flow;
        width: 100%;
    }
  
    & .status {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: left;
        float: left;
        margin-bottom: 3px;
    }
  
    & .percentage {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: right;
        float: right;
        margin-bottom: 3px;
    }
  
    & .progress {
        width: 100%;
        height: 10px;
        background: #eaeaea;
        box-shadow: inset 0px 2px 4px rgba(0,0,0,0.1);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
    }
  
    & .progress .line {
        position: absolute;
        left: 0;
        top: 0;
        height: 10px;
        width: 34%;
        background-color: #0c9;
        background-color: var(--cy__green);
        -webkit-transition: 0.3s width ease-in-out;
        transition: 0.3s width ease-in-out;
    }
}
  