.enabledisable_button {
    display: inline;
    height: 25px;
    margin-left: 10px;
    font-size: 14px;
    border-radius: 20px;
    align-self: center;
}

.task_list_element {
    display: flex;
}

.task_list_element p {
    margin-top: 4px;
    margin-bottom: 4px;
}

.task_list_element img {
    width: 15px;
    height: 15px;
    align-self: center;
    margin-right: 10px;
}


.enabled {
    color: black;
}

.disabled {
    color: lightgrey;
}

.createNew {
    margin-top: 20px;
}
