.row {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  gap: 1em;
}

.generated_access_token {
  word-break: break-all;
  border: 2px solid black;
  border-radius: 12px;
  margin-top: 1em;
  padding: 0.5em;
  user-select: none;
}