.moving_splitter_parent {
  user-select: none;
  cursor: col-resize;
}

.moving_splitter_pointer_events {
    pointer-events: none;
}

.left__panel {
  width: 100%;
  overflow-y: auto;
}

.right__panel {
  width: 100%;
  overflow-y: auto;
}

.toggler {
  width: 24px;
  height: 24px;
  position: relative;
  top: 20px;
  right: 14px;
  border: 53px;
  background-color: white;
  border-radius: var(--ds-border-radius-circle, 50%);
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  outline: 0px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: black;
  color: var(--cy__black);
  z-index: 10;

  &:hover {
    background-color: #f3f6ff;
    background-color: var(--cy__lightgrey);
    color: #36f !important;
    color: var(--cy__blue);
  }

  &:global(.left):global(.shown)::after {
    content: "<";
  }
  &:global(.left):global(.collapsed)::after {
    content: ">";
  }
  &:global(.right):global(.shown)::after {
    content: ">";
  }
  &:global(.right):global(.collapsed)::after {
    content: "<";
  }
}


.collapsed {
  width: 0;
  content: none;
}

.splitter {
  background-color: #f3f6ff;
  cursor: col-resize;
  height: auto;
  width: 4px;
  min-width: 4px;
}
