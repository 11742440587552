.admin_space_text {
  font-size: 11px;
  width: 133px;
  text-align: center;
  margin-top: -5px;
  display: flex;
}

.dbtype_release_text {
  color: red;
}